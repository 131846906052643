import {LoginModuleInterface} from "../interface/login-module-interface";
import {LoginSettingInterface} from "../interface/login-setting-interface";
export default class LoginModule implements LoginModuleInterface
{
    private _settings : LoginSettingInterface;

    constructor(settingContainerId : string)
    {
        let settingsSelector = $('#' + settingContainerId);

        if(settingsSelector.length != 0)
        {
            this._settings = JSON.parse(settingsSelector.html());
        }
    }

    init() : void
    {
        let form = $('#' + this._settings.loginFormId);
        form.find('input[name="' + this._settings.loginInputFieldName + '"]').focus();
    }

    destroy() : void
    {
    }
}